<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562.75 337.5"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M0,337.5c2.06-60.95,31.32-104.58,83.2-134.21C22.77,158.32,26.26,76.4,70.84,33.07c47.8-46.46,117.41-42,159.36.94,47.25,48.3,43,126.4-13.75,169.59,27.8,13.89,49.58,34,64.93,61.61,15.31-27.72,37.18-47.62,64.74-61.57C282.44,155.67,291.57,73,333.6,32.75,382.27-13.8,451.68-8.14,493,34.29,539.48,82,536,160.34,479.42,203.22c51.86,29.63,81.2,73.21,83.33,134.18H525.17c-.86-36.54-15.76-66.44-44.6-89-20.09-15.71-43.28-23.42-68.76-23.19-30.77.29-57.41,11.66-78.88,33.44-21.26,21.58-32.66,47.94-32.65,78.84h-37.6c-1-37-16.08-67.18-45.5-89.72C197.07,232.36,174,225,148.73,225.24a108.71,108.71,0,0,0-55.61,15.64,114.68,114.68,0,0,0-40.34,40.77c-10.11,17.13-14.91,35.8-15.09,55.85ZM487.69,112.64a74.55,74.55,0,0,0-74.92-74.92c-41.8-.1-74.86,32.76-75,74.62a75,75,0,1,0,150,.3ZM150.27,187.69A74.55,74.55,0,0,0,225,112.58c0-42-33.33-75.13-75.31-74.85C107.93,38,74.71,71.45,75,112.9A74.93,74.93,0,0,0,150.27,187.69Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconGroups',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
